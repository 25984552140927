import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on social engineering awareness.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`The Social Engineering Awareness Policy bundle is a collection of
policies and guidelines for employees of Data Migrators. This Employee
Front Desk Communication Policy is part of the Social Engineering Awareness
Policy bundle.`}</p>
    <p>{`In order to protect Data Migrators’ assets, all employees need to
defend the integrity and confidentiality of Data Migrators’
resources.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`This policy has two purposes:`}</p>
    <p>{`To make employees aware that (a) fraudulent social engineering
attacks occur, and (b) there are procedures that employees can use to
detect attacks.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Employees are made aware of techniques used for such attacks,
and they are given standard procedures to respond to attacks.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees know who to contact in these circumstances.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees recognize they are an important part of Data Migrators’ security.
The integrity of an employee is the best line of defense for protecting
sensitive information regarding Data Migrators’ resources.`}</p>
      </li>
    </ul>
    <p>{`To create specific procedures for employees to follow to help them
make the best choice when:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Someone is contacting the employee - via phone, in person,
email, fax or online - and elusively trying to collect Data Migrators’
sensitive information.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The employee is being “socially pressured” or “socially
encouraged or tricked” into sharing sensitive data.`}</p>
      </li>
    </ul>
    <h2>{`Scope`}</h2>
    <p>{`Includes all employees of Data Migrators, including temporary
contractors or part-time employees participating with help desk customer
service.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`Sensitive information of Data Migrators will not be shared
with an unauthorized individual if they use words and/or
techniques such as the following:`}</p>
    <ul>
      <li parentName="ul">{`An “urgent matter”`}</li>
      <li parentName="ul">{`A “forgotten password”`}</li>
      <li parentName="ul">{`A “computer virus emergency”
-Any form of intimidation from “higher level management”`}</li>
      <li parentName="ul">{`Any “name dropping” by the individual which gives the
appearance that it is coming from legitimate and authorized
personnel.`}</li>
      <li parentName="ul">{`The requester requires release of information that will
reveal passwords, model, serial number, or brand or quantity
of Data Migrators resources.`}</li>
      <li parentName="ul">{`The techniques are used by an unknown (not promptly
verifiable) individual via phone, email, online, fax, or in
person.`}</li>
      <li parentName="ul">{`The techniques are used by a person that declares to be
“affiliated” with Data Migrators such as a
sub-contractor.`}</li>
      <li parentName="ul">{`The techniques are used by an individual that says he/she is
a reporter for a well-known press editor or TV or radio
company.`}</li>
      <li parentName="ul">{`The requester is using ego and vanity seducing methods, for
example, rewarding the front desk employee with compliments
about his/her intelligence, capabilities, or making
inappropriate greetings (coming from a stranger).`}</li>
    </ul>
    <h3>{`Action`}</h3>
    <ol>
      <li parentName="ol">{`All persons described in section 3.0 MUST attend the
security awareness training within 30 days from the date of
employment and every 6 months thereafter.`}</li>
      <li parentName="ol">{`If one or more circumstances described in section 4.0 is
detected by a person described in section 3.0, then the
identity of the requester MUST be verified before continuing
the conversation or replying to email, fax, or online.`}</li>
      <li parentName="ol">{`If the identity of the requester described in section 5.1.1
CANNOT be promptly verified, the person MUST immediately
contact his/her supervisor or direct manager.`}</li>
      <li parentName="ol">{`If the supervisor or manager is not available, that person
MUST contact the security personnel.`}</li>
      <li parentName="ol">{`If the security personnel is not available, the person
described in section 3.0 MUST immediately drop the
conversation, email, online chat with the requester, and
report the episode to his/her supervisor before the end of
the business day.`}</li>
    </ol>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, business tool reports, internal
and external audits, and feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      